body {
  margin: 0;
  font-family: Poppins;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize !important;
}
.label {
  color: #818181;
  font-weight: 500 !important;
}
.subtitle {
  font-size: 16px !important;
  font-weight: 600;
}

/* For WebKit browsers like Chrome and Safari */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 15pt;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: rgb(51, 51, 51);
  border-radius: 21px;
}

/* For WebKit browsers like Chrome and Safari */
.horizontal-scroll::-webkit-scrollbar {
  height: 4px;
  border-radius: 15pt;
}

.horizontal-scroll::-webkit-scrollbar-track {
  background: #fff;
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background: rgb(51, 51, 51);
  border-radius: 21px;
}

/* For Firefox */
/* You can adjust the values as needed */
/* Note: Firefox supports scrollbar styling from version 64 onwards */
* {
  scrollbar-color: rgb(51, 51, 51) #fff;
  scrollbar-width: thin;
}

.MuiTabs-indicator {
  background-color: rgb(51, 51, 51) !important;
}

.MuiFormHelperText-root {
  margin-left: 0 !important;
}

.MuiTab-root.Mui-selected {
  color: #333 !important;
}

.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-disabled {
  background-color: #dfdcec5e;
}

.fc-h-event {
  border: unset;
}
.fc-daygrid-event-harness {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid #fff;
  background: #ebeff4;
}
.fc-daygrid-event {
  padding: 8px 7px;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: unset;
}

.fc .fc-button-group > .fc-button {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  background: #fff;
  color: #333;
  padding: 4px 12px;
  border: 2px solid #d9d9d9;
}

.fc-timegrid-event-harness-inset {
  border-radius: 4px;
  border: 1px solid #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}
/* .fc-event-main {
  background: #ebeff4;
} */
.fc-event-main {
  padding: 8px !important;
  border-radius: 4px;
}
.fc-v-event {
  border: unset;
}

.fc-view th,
.fc-view td {
  height: 84px;
}

.fc-view .fc-col-header thead tr th {
  height: 84px;
  border: 1px solid #ddd;
  padding: 5px;
}

.fc .fc-timegrid-slot {
  height: 40px;
}

.fc-timegrid-slots tr {
  height: 40px;
}

.fc-scrollgrid-shrink-cushion {
  color: #727272;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.fc-col-header-cell-cushion {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: #727272;
}

.fc .fc-toolbar-title {
  font-size: 20px;
  font-weight: 600;
}

.fc-more-popover {
  max-height: 300px; /* Adjust the max-height as needed */
  overflow-y: auto;
  z-index: 9 !important;
}

.scroll-hide::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.scroll-hide {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.equalHeightCard {
  display: flex;
  flex-direction: column;
}

.equalHeightCard .MuiCardContent {
  flex: 1;
}
.MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
.MuiButtonBase-root.MuiRadio-root.Mui-checked {
  color: #333;
}

.filter-label {
  font-size: 12px;
  font-weight: 500;
  color: #818181;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.dashboard-filter .MuiOutlinedInput-root {
  padding: 2px 65px 2px 5px !important;
}
.court-session-filter-clubs .MuiOutlinedInput-root {
  padding: 1px 65px 1px 5px !important;
}

.fc-timegrid-event.fc-v-event {
  min-height: 70px;
}

/* .fc-daygrid-body-unbalanced {
  display: none;
} */

.static-subtitle {
  font-weight: 600 !important;
  margin-top: 1rem !important;
  margin-bottom: 5px !important;
  font-size: 16px !important;
}

/* Chart js */
.chartMenu p {
  padding: 10px;
  font-size: 20px;
}
.chartBox {
  width: 100%;
  border-radius: 20px;
}
.container {
  overflow-x: auto;
  max-width: 100%;
  height: 350px;
  overflow-y: auto;
  /* Hide scrollbar in IE and Edge */
  -ms-overflow-style: none;
  /* Hide scrollbar in Firefox */
  scrollbar-width: none;
  scrollbar-color: #333 transparent;
}
.container::-webkit-scrollbar {
  height: 6px;
}
.container::-webkit-scrollbar-track {
  background: transparent;
}
.container::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 10px;
  border: 2px solid transparent;
}
.containerBody {
  height: 325px;
}
.status-change-description {
  color: #8f8f8f;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: -0.28px;
}

.image-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap onto multiple lines */
  justify-content: center; /* Center items horizontally */
  gap: 1rem;
}

.image-container img {
  max-width: 100%; /* Ensure images don't exceed their container's width */
  height: auto; /* Maintain aspect ratio */
}

.fixed-width {
  max-width: 200px;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rbc-toolbar {
  display: none !important;
}
.rbc-allday-cell {
  display: none;
}
.rbc-header {
  font-size: 15px !important;
}
.rbc-time-slot {
  min-height: 45px;
}
.rbc-event-content {
  font-size: 13px;
}
.rbc-event-label {
  font-weight: 600;
}
.rbc-time-gutter.rbc-time-column .MuiCheckbox-colorPrimary {
  display: none;
}
.rbc-time-gutter.rbc-time-column .rbc-time-slot {
  padding: 5px;
  font-size: 13px;
  font-weight: 500;
}
.rbc-day-slot .rbc-events-container {
  margin-right: 0 !important;
}
@media screen and (max-width: 600px) {
  .image-container {
    flex-direction: column; /* Stack items vertically on small screens */
    align-items: center; /* Center items vertically */
  }
}

.fc-daygrid-body-unbalanced.fc-daygrid-body-natural{
  display: none;
}

.custom-column-header-right {
  justify-content: flex-end;
  text-align: right;
}

.custom-column-header-right .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-end;
}

.custom-column-header-center .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.hylink {
  text-transform: unset;
  text-decoration: none;
  color: #326fd1;
  font-weight: 500;
}

.hylink:visited {
  color: #326fd1;
}