.ql-editor {
  font-size: 14px;
  line-height: 1.459;
  font-family: "Inter-Regular";
}
.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: 0px;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  top: unset;
  bottom: 100%;
}
.quillEditor {
  /* border: 1px solid #cacaca; */
  border-radius: 5px;
}
.hidden {
  position: absolute;
  top: 0;
  z-index: -1;
  visibility: hidden;
}
.quill-view .ql-editor {
  padding: 15px 0;
}
.ql-editor.ql-blank::before {
  font-style: inherit;
  font-family: Poppins;
}
